import { mapMutations, mapActions } from 'vuex';
import { getClientType, queryStringToObj, urlUnescape } from '@/utils/util';
import Storage from '@/utils/storage';
export default {
  name: 'Home',
  data() {
    return {};
  },
  created() {
    if (window.location.search) {
      const search = window.location.search;
      window.location.replace(`/#/${search}`);
    }
    const {
      code = '',
      state = ''
    } = this.$route.query;
    if (code) {
      this.handleUserLogin(code, state);
    }
  },
  methods: {
    ...mapMutations('user', ['setToken']),
    ...mapActions('user', ['userLoginByCode']),
    // 根据企微code登录
    async handleUserLogin(code, state) {
      var _res$error;
      this.setToken(''); // 移除之前的token
      const res = await this.userLoginByCode({
        code
      });
      if (res !== null && res !== void 0 && res.data) {
        if (state !== 'STATE') {
          // 解析state，处理消息跳转
          // '&'转义，避免参数丢失，'^&'解义，处理跳转参数
          let decodeState = decodeURIComponent(state);
          // 因为传参时转码了2次，所以企业微信需要解码两次（微信会自动解码一次）
          decodeState = decodeURIComponent(decodeState);
          let stateParams = urlUnescape(decodeState);
          stateParams = queryStringToObj(stateParams);
          let {
            path = '',
            ...rest
          } = stateParams;
          path = decodeURIComponent(path);
          await this.$router.replace({
            path,
            query: rest
          });
        } else {
          this.handlePageJump();
        }
      } else if ((res === null || res === void 0 || (_res$error = res.error) === null || _res$error === void 0 ? void 0 : _res$error.code) === 'salarysheet.ex.10032') {
        // 用户在系统中不存在，跳转到暂无数据页
        await this.$router.replace('/payslip-noData');
      }
    },
    // 页面跳转处理
    handlePageJump() {
      const client = getClientType();
      if (client === 'mobile') {
        // 移动端：工资条列表页
        this.$router.replace('/payslip-list');
      } else {
        // pc端：登录管理后台
        this.$router.replace('/payslip');
      }
    }
  }
};